import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

// Local imports
import Layout from "src/layout";
import PostListingSlim from "src/components/generic/PostListingSlim/PostListingSlim";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

import styles from "./index.module.scss";

const Index = ({ data }) => {
	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO />
			<section className="container m-b-xl">
				<div className="columns is-mobile">
					<div
						className={`${styles.personalImageContainer} column is-one-third-tablet is-half-mobile m-b-md`}
					>
						<Img
							fluid={data.domFace.childImageSharp.fluid}
							alt=""
							fadeIn={false}
							className={styles.personalImage}
						/>
					</div>
					<div
						className={`column is-two-third-desktop is-half-tablet is-full-mobile ${styles.whatIDoBlock}`}
					>
						<h2>Data for SEO teams</h2>
						<p>
							With software + consulting, I help SEO teams level
							up their analysis.
						</p>
						<p>
							Search console, analytics, backlink, log & crawl
							data. I help teams pull it together to run faster,
							more repeatable and scaleable analysis.
						</p>
						<Link
							className="button is-primary is-outlined m-b-m"
							to={"/data-for-seo/"}
						>
							Read More
						</Link>
						<h2>Training</h2>
						<p>
							I help create great marketers. No-one ever mastered
							something while bored, so you&#39;ll find no long
							powerpoints here.
						</p>
						<Link
							className="button is-primary is-outlined"
							to={"/training/"}
						>
							Read More
						</Link>
					</div>
				</div>
			</section>
			<div className="greyBar">
				<section className={`container ${styles.readMore}`}>
					<div className="columns p-t-lg p-b-lg is-desktop">
						<div className="column is-one-third-desktop">
							<span className="redBox"></span>
							<h2 className={`m-b-m`}>Learn</h2>
							<PostListingSlim postEdges={data.text.edges} />
						</div>
						<div className="column is-one-third-desktop">
							<span className="redBox"></span>
							<h2 className={`m-b-m`}>Watch</h2>
							<PostListingSlim postEdges={data.watch.edges} />
						</div>
						<div className="column is-one-third-desktop">
							<span className="redBox"></span>
							<h2 className={`m-b-m `}>Tools</h2>
							<PostListingSlim postEdges={data.tools.edges} />
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

{
	/* <h2>Consulting</h2>
<p>
	I help companies with organic search. I specialise
	in very large sites and B2B.
</p> */
}

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query IndexQuery {
		# This gets us all of the nodes.
		tools: allMarkdownRemark(
			limit: 2000
			sort: {
				fields: [frontmatter___specifiedOrder, fields___date]
				order: [DESC, DESC]
			}
			filter: { frontmatter: { post_type: { regex: "/tools/" } } }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						h1title
						categories
					}
				}
			}
		}
		text: allMarkdownRemark(
			limit: 2000
			sort: {
				fields: [frontmatter___specifiedOrder, fields___date]
				order: [DESC, DESC]
			}
			filter: { frontmatter: { post_type: { regex: "/text/" } } }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						h1title
						categories
					}
				}
			}
		}
		watch: allMarkdownRemark(
			limit: 2000
			sort: {
				fields: [frontmatter___specifiedOrder, fields___date]
				order: [DESC, DESC]
			}
			filter: { frontmatter: { post_type: { regex: "/watch/" } } }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						h1title
						categories
					}
				}
			}
		}
		# This gets us the logo1
		domFace: file(relativePath: { eq: "images/pages/face.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 890, maxHeight: 890) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

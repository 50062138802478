import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function PostListingSlim({ postEdges, limit }) {
	const reducedPostList = postEdges.reduce((acc, postEdge) => {
		if (acc.length < limit) {
			acc.push({
				path: postEdge.node.fields.slug,
				h1title: postEdge.node.frontmatter.h1title
			});
		}

		return acc;
	}, []);

	const renderedPostList = reducedPostList.map(post => {
		return (
			<div className="m-b-m">
				<Link to={post.path} key={post.title} className="hoverLink">
					<span>{post.h1title}</span>
				</Link>
			</div>
		);
	});

	return <div>{renderedPostList}</div>;
}

PostListingSlim.defaultProps = {
	limit: 5
};

PostListingSlim.propTypes = {
	postEdges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	limit: PropTypes.number
};

export default PostListingSlim;
